.matrix-canvas {
    display: block;
    background: transparent;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}

.content-wrapper {
    position: relative;
    z-index: 1;
    backdrop-filter: blur(8px);
    /* Apply blur effect */
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    height: 100%;
    overflow: auto;
}


@media (max-width: 768px) {
    .content-wrapper {
        padding: 10px;
        font-size: 14px;
    }
}