/* Import the font */
@font-face {
    font-family: 'KGHappySolid';
    src: url('../fonts/KGHAPPYSolid.ttf') format('truetype');
}

.how-to-buy-container {
    background-color: #202020;
    padding: 5%;
    text-align: center;
    font-family: 'KGHappySolid', sans-serif;
}

.how-to-buy-title {
    font-family: 'KGHappy', sans-serif;
    margin-bottom: 5%;
    font-size: 3rem;
    color: #ffbb01;
}

.step {
    background-color: #ffe9ae;
    color: #202020;
    display: flex;
    align-items: center;
    padding: 2%;
    border-radius: 1em;
    margin: 5% 0;
    border: 2px solid #ffbb01;
}

.step-image {
    width: 20%;
    height: auto;
    margin-right: 2%;
    border-radius: 1em;
    border: 2px solid #ffbb01;
}

.step-description {
    font-family: 'KGHappySolid', sans-serif;
    font-size: 1.5rem;
    text-align: left;
    flex: 1;
}

.step-description p {
    margin: 0;
    padding: 0.5em 0;
}

.custom-link {
    color: inherit;
    text-decoration: none;
    font-weight: bold;
}

.custom-link:hover {
    text-decoration: underline;
}

@media (max-width: 768px) {
    .step {
        flex-direction: column;
        text-align: center;
    }

    .step-image {
        margin: 0 0 2% 0;
        width: 40%;
    }

    .step-description {
        font-size: 1rem;
    }
}