@font-face {
    font-family: 'KGHappy';
    src: url('../fonts/kg_happy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KidsMagazine';
    src: url('../fonts/kids_magazine.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.footer {
    background-color: #202020;
    color: #ebebeb;
    font-family: 'KGHappySolid', sans-serif;
    padding: 20px;
    text-align: center;
    margin-top: auto;
    
}