@import url("https://fonts.cdnfonts.com/css/kids-magazine");

html,
body {
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}

body {
  overflow-x: hidden;
}

/* Ensure the navbar has a higher z-index than the Matrix */
.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  position: absolute;
  /* Change from relative to absolute */
  top: 0;
  left: 0;
  z-index: 4;
  /* Ensure it is on top of the Matrix */
}

.navbar-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  /* Center text for smaller screens */
}

.navbar-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  /* Wrap items on smaller screens */
  gap: 20px;
  /* Reduced gap between items */
  padding: 0;
  margin: 0;
}

.navbar-item {
  font-family: "KGHAPPY", sans-serif;
  margin: 0;
  text-align: center;
  /* Center text for smaller screens */
  flex: 1 0 auto;
  /* Equal width items */
}

.navbar-link {
  text-decoration: none;
  color: #ebebeb;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-size: 30px;
  /* Reduced font size for smaller screens */
  display: inline-block;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out,
    letter-spacing 0.2s ease-in-out;
  z-index: 10;
}

.char {
  display: inline-block;
  transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
}

.navbar-link:hover {
  letter-spacing: 4px;
}

.navbar-link:hover .char {
  transform: scale(1.2);
  animation: rainbow 1s infinite alternate;
}

@keyframes rainbow {
  0% {
    color: #ebebeb;
  }

  57.12% {
    color: #ffbb01;
  }

  100% {
    color: #ffe9ae;
  }
}

.navbar-right {
  margin-left: auto;
  position: absolute;
  right: 20px;
}

@media (max-width: 1200px) {
  .navbar-link {
    font-size: 25px;
    /* Adjusted font size for medium screens */
  }
}

@media (max-width: 768px) {
  .navbar-link {
    font-size: 20px;
    /* Further reduced font size for smaller screens */
  }
  .navbar-list {
    gap: 10px;
  }
  .navbar-link:hover {
    letter-spacing: 0px;
  }

  .navbar-link:hover .char {
    transform: scale(1.01);
  }
}

@media (max-width: 450px) {
  .navbar-link {
    font-size: 15px;
    /* Smallest font size for very small screens */
  }
  .navbar-list {
    gap: 10px;
  }
  .navbar-link:hover {
    letter-spacing: 0px;
  }

  .navbar-link:hover .char {
    transform: scale(1.01);
  }
}
