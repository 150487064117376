/* Importing fonts *

/* Define CSS variables for colors and fonts */
:root {
  --background-color: #ffe9ae;
  --text-color-main: #ffbb01;
  --text-color-secondary: #202020;
  --font-main: 'KGHappy', sans-serif;
  --font-secondary: 'KGHappySolid', sans-serif;
}

body {
  margin: 0;
  background-color: var(--background-color);
}