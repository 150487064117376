@font-face {
    font-family: 'KGHappy';
    src: url('../fonts/kg_happy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
}

.header-container {
    position: relative;
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    margin-top: 4rem;
}

.header-content {
    width: 100%;
    text-align: center;
    color: #202020;
    z-index: 10;
}

.pepeonblast-container {
    display: flex;
    gap: 0.5rem;
    /* Adjust spacing between words if needed */
}

.pepeonblast-title {
    font-family: 'KGHappy', sans-serif;
    font-size: 6rem;
    color: #ffbb01;
    z-index: 0;
    margin: 0;
    padding: 0;
    text-shadow: -2px 2px 0 #000,
        -15px 10px 0 #202020,
        -15px 10px 0 #202020,
        -15px 10px 0 #202020;
    transition: color 0.3s ease, text-shadow 0.3s ease, transform 0.3s ease;
}

.pepeonblast-title:hover {
    color: #ffe9ae;
    text-shadow: -2px 2px 10px #000,
            -15px 10px 10px #202020,
            -15px 10px 10px #202020,
            -15px 10px 10px #202020;
    /* Invert shadow color */
    transform: scale(1.1);
    /* Scale up on hover */
}

.typewriter-text {
    font-family: 'KGHappy', sans-serif;
    font-size: 3rem;
    margin-bottom: 20px;
}

@keyframes bounce {

    0%,
    100% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }
}

.action-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12vw; /* Space between socials and buy button */
}

.socials-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 20px;
}

.buy-button {
    background-color: rgba(255, 180, 5, 0);
    color: #202020;
    border: 2px solid #202020;
    border-radius: 25px;
    padding: 15px 30px;
    font-size: 2rem;
    font-family: 'KGHappy', sans-serif;
    cursor: pointer;
    margin-bottom: 20px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    backdrop-filter: blur(1px);
}

.buy-button:hover {
    background-color: rgba(255, 187, 1, 1);
    color: #202020;
    transform: scale(1.15);
    animation: wiggle 0.5s;
    backdrop-filter: blur(0px);
}

.icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #202020;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: transform 0.3s ease, background-color 0.3s ease;
}

.icon:hover {
    transform: scale(1.15);
    background-color: #ffbb01;
    animation: wiggle 0.5s;
}

.icon-img {
    width: 60%;
    height: 60%;
}

@keyframes wiggle {

    0%,
    100% {
        transform: rotate(-3deg) scale(1.15);
    }

    50% {
        transform: rotate(3deg) scale(1.15);
    }
}

.characters {
    position: absolute;
    width: 100%;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.character {
    position: absolute;
    bottom: 0;
    opacity: 0;
    animation: fade-in 1s forwards;
}

.character:nth-child(1) {
    animation-delay: 0.5s;
}

.character:nth-child(2) {
    animation-delay: 1s;
}

.character:nth-child(3) {
    animation-delay: 1.5s;
}

.character:nth-child(4) {
    animation-delay: 2s;
}

.character:nth-child(5) {
    animation-delay: 2.5s;
}

.character:nth-child(6) {
    animation-delay: 3s;
}

.character:nth-child(7) {
    animation-delay: 3.5s;
}

@keyframes fade-in {
    to {
        opacity: 1;
        transform: translateX(0);
    }

    from {
        opacity: 0;
        transform: translateX(-20px);
    }
}

.bounce {
    animation: bounce 2s infinite;
}

.wiggle-hover:hover {
    animation: wiggle 0.5s;
    transform: scale(1.15);
}

@media (max-width: 1268px) {
    .pepeonblast-title {
        font-size: 5rem;
        text-shadow: -2px 2px 0 #000,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020;
    }

    .typewriter-text {
        font-size: 2.5rem;
    }

    .buy-button {
        font-size: 2.5rem;
        padding: 10px 80px;
    }

    .header-container {
        height: 85vh;
    }

    .action-container {
        gap: 8vw; /* Adjusted gap */
    }

    .icon {
        width: 70px;
        height: 70px;
    }

    .icon-img {
        width: 70%;
        height: 70%;
    }

    .buy-button {
        padding: 10px 20px;
    }
}

@media (max-width: 768px) {
    .pepeonblast-title {
        font-size: 4rem;
        text-shadow: -2px 2px 0 #000,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020;
    }

    .typewriter-text {
        font-size: 1.75rem;
    }

    .buy-button {
        font-size: 2rem;
        padding: 10px 50px;
    }

    .header-container {
        height: 70vh;
    }

    .action-container {
        gap: 5vw; /* Adjusted gap */
    }

    .icon {
        width: 50px;
        height: 50px;
    }

    .icon-img {
        width: 70%;
        height: 70%;
    }

    .buy-button {
        padding: 8px 15px;
    }
}

@media (max-width: 576px) {
    .pepeonblast-title {
        font-size: 3.3rem;
        text-shadow: -2px 2px 0 #000,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020;
    }

    .typewriter-text {
        font-size: 2rem;
    }

    .buy-button {
        font-size: 2rem;
        padding: 10px 30px;
    }

    .header-container {
        height: 80vh;
    }

    .action-container {
        flex-direction: column;
        gap: 1rem; /* Adjusted gap */
    }

    .icon {
        width: 50px;
        height: 50px;
    }

    .icon-img {
        width: 70%;
        height: 70%;
    }

    .buy-button {
        padding: 12px 48px;
    }
}

@media (max-width: 480px) {
    .pepeonblast-title {
        font-size: 2.5rem;
        text-shadow: -2px 2px 0 #000,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020;
    }

    .typewriter-text {
        font-size: 1.5rem;
    }

    .buy-button {
        font-size: 1.5rem;
        padding: 12px 25px;
    }

    .header-container {
      height: 60vh;
    }

    .action-container {
        flex-direction: column;
        gap: 1rem; /* Adjusted gap */
    }

    .icon {
        width: 50px;
        height: 50px;
    }

    .icon-img {
        width: 70%;
        height: 70%;
    }

    .buy-button {
        padding: 8px 15px;
    }
}

@media (max-width: 400px) {
    .pepeonblast-title {
        font-size: 2.2rem;
        text-shadow: -2px 2px 0 #000,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020,
                -5px 2px 0 #202020;
    }

    .typewriter-text {
        font-size: 1.25rem;
    }

    .buy-button {
        font-size: 1.5rem;
        padding: 12px 25px;
    }

    .header-container {
        height: 55vh;
    }

    .action-container {
        flex-direction: column;
        gap: 0.5rem; /* Adjusted gap */
    }

    .icon {
        width: 45px;
        height: 45px;
    }

    .icon-img {
        width: 70%;
        height: 70%;
    }

    .buy-button {
        padding: 6px 25px;
    }
}

@media (max-width: 320px) {
    .pepeonblast-title {
        font-size: 1.75rem;
        text-shadow: -2px 2px 0 #000,
            -5px 2px 0 #202020,
            -5px 2px 0 #202020,
            -5px 2px 0 #202020;
    }

    .typewriter-text {
        font-size: 1rem;
    }

    .buy-button {
        font-size: 1rem;
        padding: 12px 25px;
    }

    .header-container {
        height: 50vh;
    }

    .action-container {
        flex-direction: column;
        gap: 0.5rem; /* Adjusted gap */
    }

    .icon {
        width: 35px;
        height: 35px;
    }

    .icon-img {
        width: 100%;
        height: 70%;
    }

    .buy-button {
        padding: 6px 10px;
    }
}

/* Individual character styles */
.prettyboy {
    width: 18%;
    height: auto;
    left: 0%;
    z-index: 2;
}

.ser {
    width: 12%;
    height: auto;
    left: 17%;
    z-index: 1;
}

.jeff {
    width: 12%;
    height: auto;
    left: 29%;
    z-index: 1;
}

.benKen {
    width: 25%;
    height: auto;
    left: 39.75%;
    z-index: 0;
}

.jeeter {
    width: 16%;
    height: auto;
    left: 58%;
    z-index: 1;
}

.papaPapajr {
    width: 15%;
    height: auto;
    left: 72%;
    z-index: -1;
}

.kol {
    width: 20%;
    height: auto;
    right: 0%;
    z-index: 3;
}
