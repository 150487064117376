@import url('https://fonts.googleapis.com/css2?family=KGHappySolid&display=swap');

.careers-page {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #202020;
  padding: 20px;
}

.careers-container {
  margin: 1rem 1rem;
  text-align: center;
  padding: 50px;
  width: 100%;
}

.title {
  font-family: 'KGHappy', sans-serif;
  font-size: 4vw;
  color: #ffbb01;
  text-transform: capitalize;
}

.job-cards {
  margin: 6rem 6rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 2fr));
  gap: 20px;
  justify-content: center;
}

.job-card {
  background-color: #ffe9ae;
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.2s;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
}

.job-card:hover {
  transform: scale(1.05);
}

.job-image-container {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  border: 4px solid #202020;
  border-radius: 10px;
  margin-bottom: 15px;
}

.job-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.job-content {
  text-align: center;
}

.job-title {
  font-family: 'KGHappySolid', sans-serif;
  font-size: 1.5em;
  margin: 10px 0;
  color: #202020;
}

.job-description {
  font-family: 'KGHappySolid', sans-serif;
  font-size: 1em;
  color: #202020;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  font-family: 'KGHappySolid', sans-serif;
  background-color: #ffe9ae;
  padding: 30px;
  border-radius: 20px;
  width: 90%;
  max-width: 400px;
  text-align: center;
}

.modal-title {
  font-family: 'KGHappySolid', sans-serif;
  font-size: 2em;
  margin-bottom: 30px;
  color: #202020;
}

.modal-content input {
  width: calc(100% - 40px);
  padding: 15px;
  margin: 15px 0;
  border: 2px solid #202020;
  border-radius: 10px;
}

.modal-content button {
  font-family: 'KGHappySolid', sans-serif;
  background-color: #ffbb01;
  border: none;
  padding: 15px 30px;
  margin: 10px 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: inline-block;
}

.modal-content button:hover {
  background-color: #ffc726;
}

.modal-message {
  font-size: 1.5em;
}

.modal-message p {
  font-family: 'KGHappySolid', sans-serif;
  margin-bottom: 30px;
  color: #202020;
}



@media (max-width: 1280px) {
  .title {
    font-size: 7vw;
    margin-bottom: 1.5rem;
  }

  .job-cards {
    grid-template-columns: repeat(500px, minmax(400px, 2fr));
  }

  .job-card {
    max-width: 100%;
  }

  .job-title {
    font-size: 1.3em;
  }

  .job-description {
    font-size: 0.9em;
  }
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .title {
    font-size: 9vw;
    margin-bottom: 1.5rem;
  }

    .job-cards {
      grid-template-columns: repeat(auto-fit, minmax(400px, 2fr));
    }

  .job-card {
    max-width: 100%;
  }

  .job-title {
    font-size: 1.3em;
  }

  .job-description {
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .title {
    font-size: 7vw;
    margin-bottom: 1rem;
  }

    .job-cards {
      grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
    }

  .job-card {
    max-width: 100%;
  }

  .job-title {
    font-size: 1.2em;
  }

  .job-description {
    font-size: 0.8em;
  }

  .modal-content {
    padding: 20px;
  }

  .modal-title {
    font-size: 1.8em;
    margin-bottom: 20px;
  }

  .modal-content input {
    padding: 10px;
    margin: 10px 0;
  }

  .modal-content button {
    padding: 10px 20px;
    margin: 5px;
  }

  .modal-message {
    font-size: 1.2em;
  }

  .modal-message p {
    margin-bottom: 20px;
  }
}

  @media (max-width: 400px) {
  .title {
    font-size: 8vw;
  }

  .job-cards {
    grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
  }

  .job-card {
    max-width: 100%;
  }

  .job-title {
    font-size: 1.2em;
  }

  .job-description {
    font-size: 0.8em;
  }

  .modal-content {
    padding: 20px;
  }

  .modal-title {
    font-size: 1.8em;
  }

  .modal-content input {
    padding: 10px;
    margin: 10px 0;
  }

  .modal-content button {
    padding: 10px 20px;
    margin: 5px;
  }

  .modal-message {
    font-size: 1.2em;
  }

  .modal-message p {
    margin-bottom: 20px;
  }
}
