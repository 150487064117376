@font-face {
    font-family: 'KGHappy';
    src: url('../../fonts/kg_happy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.hall-of-memes {
    flex: 1;
    display: flex;
    flex-direction: column;
    background-color: #202020;
    padding: 20px;
    overflow-y: auto;
}

.memes-title {
    font-family: 'KGHappy', sans-serif;
    font-size: 3.5rem;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #EBEBEB;
    letter-spacing: 5px;
    text-transform: lowercase;
    margin-top: 3rem;
}

.memes-title span {
    display: inline-block;
    transition: transform 0.3s, color 0.3s;
    cursor: pointer;
    text-decoration: none;
}

.memes-title .default-font {
    font-family: sans-serif;
}

.memes-title span:hover {
    transform: scale(1.1);
    color: var(--random-color);
    text-shadow:
        -1px -1px 0 #202020,
        1px -1px 0 #202020,
        -1px 1px 0 #202020,
        1px 1px 0 #202020;
}

.memes-title span:hover {
    --random-color: #ffbb01;
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.filter-button {
    font-family: 'KGHappySolid', sans-serif;
    font-size: 1.5rem;
    background: transparent;
    border: 2px solid #ffbb01;
    color: #ffbb01;
    border-radius: 25px;
    padding: 5px 10px;
    margin: 1rem;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s, transform 0.3s;
}

.filter-button.selected {
    background-color: #ffbb01;
    color: #202020;
}

.filter-button:hover {
    transform: scale(1.05);
    background-color: #ffbb01;
    color: #202020;
}

.meme-grid {
    display: grid;
    grid-gap: 30px;
    margin: 0 80px;
    padding-bottom: 20px;
}

/* Default number of columns per category */
.meme-grid-memes {
    grid-template-columns: repeat(4, 1fr);
}

.meme-grid-characters {
    grid-template-columns: repeat(6, 1fr);
}

.meme-grid-branding {
    grid-template-columns: repeat(5, 1fr);
}

.meme-card {
    position: relative;
    width: 100%;
    padding-top: 100%;
    /* This ensures the card is square */
    cursor: pointer;
    border-radius: 6px;
    overflow: hidden;
    border: 2px solid #ffbb01;
    transition: border-color 0.3s ease, transform 0.3s;
    background-size: cover;
    background-position: center;
    background-color: #202020;
}

.meme-card img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 6px;
}

.meme-card:hover {
    border-color: #ebebeb;
    transform: scale(1.03);
}

.meme-number {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: #ffbb01;
    color: #202020;
    padding: 4px 8px;
    border-radius: 12px;
    font-size: 0.9rem;
    font-family: 'KGHappy', sans-serif;
}

.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    z-index: 1000;
    box-sizing: border-box;
    overflow-y: auto;
}

.modal-content {
    background: #efefef;
    border-radius: 10px;
    position: relative;
    box-sizing: border-box;
    max-width: 90%;
    max-height: 90%;
    overflow: hidden;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}

.modal-images {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.modal-images img {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
    border-radius: 10px;
    object-fit: contain;
}

.download-button {
    position: absolute;
    top: 10px;
    left: 10px;
    margin-top: 10px;
    margin-left: 10px;
    background: #202020;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 1;
    transition: background-color 0.3s, transform 0.3s;
}

.download-button::before {
    content: "";
    background: url('../../assets/download.webp') no-repeat center center;
    background-size: 20px 20px;
    width: 20px;
    height: 20px;
}

.download-button:hover {
    background: #ebebeb;
    transform: scale(1.2);
}

.download-button:hover::before {
    background: url('../../assets/download2.webp') no-repeat center center;
    background-size: 20px 20px;
}

.branding-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-around;
    background-color: #202020;
    padding: 10px 0;
    z-index: 1000;
}

.branding-footer-element {
    color: #ebebeb;
    cursor: pointer;
    font-family: 'KGHappySolid', sans-serif;
    text-align: center;
}

.branding-footer-element .main-color {
    color: #ffbb01;
}

.branding-footer-element .secondary-color {
    color: #ffe9ae;
}

.branding-footer-element a {
    color: inherit;
    text-decoration: none;
    font-family: inherit;
    transition: color 0.3s;
}

.branding-footer-element a:hover {
    color: #ffbb01;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    flex-wrap: wrap;
}

.pagination-button {
    font-family: 'KGHappySolid', sans-serif;
    font-size: 1rem;
    background: transparent;
    border: 1px solid #ffbb01;
    color: #ffbb01;
    border-radius: 4px;
    padding: 5px 10px;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
}

.pagination-button.selected,
.pagination-button:hover {
    background-color: #ffbb01;
    color: #202020;
}

.pagination-button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-ellipsis {
    margin: 5px;
    color: #ffbb01;
    font-size: 1rem;
    font-family: 'KGHappySolid', sans-serif;
}

/* Media Queries */

@media (max-width: 1200px) {
    .meme-grid-memes {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        margin: 0 30px;
        padding-bottom: 30px;
    }

    .meme-grid-characters {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;
        margin: 0 30px;
        padding-bottom: 30px;
    }

    .meme-grid-branding {
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 20px;
        margin: 0 30px;
        padding-bottom: 30px;
    }

    .memes-title {
        font-size: 2rem;
    }

    .filter-button {
        font-size: 1rem;
        padding: 5px 8px;
        margin: 0.5rem;
    }

    .branding-footer {
        flex-direction: column;
        align-items: center;
    }

    .branding-footer-element {
        margin: 5px 0;
    }
}

@media (max-width: 768px) {
    .meme-grid-memes {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        margin: 0 20px;
        padding-bottom: 20px;
    }

    .meme-grid-characters {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        margin: 0 20px;
        padding-bottom: 20px;
    }

    .meme-grid-branding {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 15px;
        margin: 0 20px;
        padding-bottom: 20px;
    }

    .close-button {
        font-size: 24px;
    }

    .memes-title {
        font-size: 2rem;
    }

    .filter-button {
        font-size: 1rem;
        padding: 4px 6px;
        margin: 0.5rem;
    }

    .branding-footer {
        flex-direction: column;
        align-items: center;
    }

    .branding-footer-element {
        margin: 0px 0;
    }

    .modal-images img {
        max-width: 100%;
        max-height: 80vh;
    }
}

@media (max-width: 480px) {
    .meme-grid-memes {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        margin: 0 10px;
        padding-bottom: 10px;
    }

    .meme-grid-characters {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        margin: 0 10px;
        padding-bottom: 10px;
    }

    .meme-grid-branding {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        margin: 0 10px;
        padding-bottom: 10px;
    }

    .memes-title {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .filter-button {
        font-size: 0.9rem;
        padding: 3px 5px;
        margin: 0.5rem;
    }

    .branding-footer {
        flex-direction: column;
        align-items: center;
    }

    .branding-footer-element {
        margin: 0px 0;
    }

    .pagination-button {
        font-size: 0.9rem;
        padding: 4px 8px;
    }

    .modal-images img {
        max-width: 100%;
        max-height: 80vh;
    }
}