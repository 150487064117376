@font-face {
    font-family: 'KGHappy';
    src: url('../fonts/kg_happy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KGHappySolid';
    src: url('../fonts/KGHAPPYSolid.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.about {
    background-color: #202020;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem 1rem;
    box-sizing: border-box;
}

.title-about {
    color: #ffbb01;
    font-family: 'KGHappy', sans-serif;
    font-size: 3rem;
    margin-bottom: 1.5rem;
    text-align: center;
}

.main-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
}

.left-section,
.right-section {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
}

.image-box {
    border: 0.2rem solid #ffbb01;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35vw;
    height: 35vw;
    border-radius: 1rem;
    overflow: hidden;
    position: relative;
}

.current-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease-in-out;
}

.description-container {
    color: #ffe9ae;
    font-family: 'KGHappySolid', sans-serif;
    text-align: center;
    width: 80%;
    max-width: 500px;
    margin-top: 1rem;
}

.description-title {
    color: #ffbb01;
    font-size: 2.5rem;
    margin-bottom: 1.5rem;
}

.description-wrapper {
    height: 25rem;
    overflow: hidden;
}

.description {
    font-size: 1.5rem;
    line-height: 2rem;
}

.bottom-section {
    width: 100%;
    margin-top: 1rem; /* Adjusted margin for better spacing */
}

.marquee-item {
    display: inline-block;
    margin: 1rem;
    cursor: pointer;
}

.marquee-image {
    width: 16vw;
    height: 16vw;
    border-radius: 0.5rem;
    object-fit: cover;
    transition: transform 0.2s ease-in-out;
}

.marquee-image:hover {
    transform: scale(1.1);
}

/* Responsive design */
@media (max-width: 992px) {
    .main-container {
        flex-direction: column; /* Stack sections vertically */
        align-items: center;
    }

    .left-section,
    .right-section {
        width: 100%; /* Full width for sections */
        margin: 0; /* Remove margins */
        margin-bottom: 1rem; /* Add margin at the bottom for spacing */
    }

    .image-box {
        width: 60vw;
        height: 60vw;
    }

    .description-title {
        font-size: 2rem;
    }

    .description {
        font-size: 1.2rem;
    }

    .bottom-section {
        margin-top: -20rem; /* Further reduce margin to remove extra space */
    }

    .marquee-image {
        width: 20vw;
        height: 20vw;
    }
}

@media (max-width: 768px) {
    .about {
        padding: 2rem 1rem;
    }

    .image-box {
        width: 70vw;
        height: 70vw;
    }

    .description-title {
        font-size: 1.8rem;
    }

    .description {
        font-size: 1.2rem;
    }

    .bottom-section {
        margin-top: -20rem; /* Further reduce margin to remove extra space */
    }

    .marquee-image {
        width: 25vw;
        height: 25vw;
    }
}

@media (max-width: 576px) {
    .title-about {
        font-size: 1.8rem;
    }

    .image-box {
        width: 80vw;
        height: 80vw;
    }

    .description-title {
        font-size: 1.5rem;
    }

    .description {
        font-size: 1rem;
    }

    .bottom-section {
        margin-top: -20rem; /* Further reduce margin to remove extra space */
    }

    .marquee-image {
        width: 30vw;
        height: 30vw;
    }
}

@media (max-width: 450px) {
    .title-about {
        font-size: 1.5rem;
    }

    .image-box {
        width: 90vw;
        height: 90vw;
    }

    .description-title {
        font-size: 1.3rem;
    }

    .description {
        font-size: 0.9rem;
    }

    .bottom-section {
        margin-top: -20rem; /* Further reduce margin to remove extra space */
    }

    .marquee-image {
        width: 35vw;
        height: 35vw;
    }
}
