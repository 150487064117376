@font-face {
    font-family: "KGHappy";
    src: url("../fonts/kg_happy.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
}

section {
    min-height: 50vh;
    width: 100vw;
    background-color: #ffe9ae;
    position: relative;
    display: inline-block;
    overflow: hidden;
}

.title-tok {
    font-family: "KGHappy", sans-serif;
    font-size: 4rem;
    text-transform: capitalize;
    color: #202020;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    border-bottom: 2px solid;
    width: fit-content;
}

@media (max-width: 40em) {
    .title {
        font-size: 2rem;
    }
}

.container {
    width: 90%;
    height: 300vh;
    background-color: var(--body);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media (max-width: 128em) {
    .container {
        width: 80%;
        height: 300vh;
    }
}

@media (max-width: 64em) {
    .container {
        width: 80%;
        height: 110vh;
    }
}

@media (max-width: 48em) {
    .container {
        width: 90%;
        height: 100vh;
    }
}

.svg-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: relative;
}

.hidden-image {
    position: absolute;
    opacity: 1;
    max-width: 50%;
    padding: 2%;
}

@keyframes float-left {
    0% {
        transform: translate(0, 0);
    }

    20% {
        transform: translate(-10px, -20px);
    }

    40% {
        transform: translate(-20px, -10px);
    }

    60% {
        transform: translate(-10px, -20px);
    }

    80% {
        transform: translate(-20px, -10px);
    }

    100% {
        transform: translate(0, 0);
    }
}

@keyframes float-right {
    0% {
        transform: translate(0, 0);
    }

    20% {
        transform: translate(10px, 20px);
    }

    40% {
        transform: translate(20px, 10px);
    }

    60% {
        transform: translate(10px, 20px);
    }

    80% {
        transform: translate(20px, 10px);
    }

    100% {
        transform: translate(0, 0);
    }
}

.hidden-image.left {
    left: -5%;
    animation: float-left 8s ease-in-out infinite;
}

.hidden-image.right {
    right: -5%;
    animation: float-right 8s ease-in-out infinite;
}