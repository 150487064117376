.tokenomics {
    background-color: #ffe9ae;
    text-align: center;
    color: #202020;
    overflow: hidden;
    /* Ensure the moving items stay within the container */
}

.tokenomics-title {
    font-family: 'KGHappy', sans-serif;
    font-size: clamp(2rem, 5vw, 4rem);
    margin-bottom: 5vh;
}