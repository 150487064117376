:root {
    --draw-multiplier: 11.;
}




@media (max-width: 128em) {
    :root {
        --draw-multiplier: 7.2;
    }
}
@media (max-width: 120em) {
    :root {
        --draw-multiplier: 7;
    }
}

@media (max-width: 64em) {
    :root {
        --draw-multiplier: 2.5;
    }
}

@media (max-width: 48em) {
    :root {
        --draw-multiplier: 2.1;
    }
}

@media (max-width: 1250px) {
    :root {
        --draw-multiplier: 7.89;
    }
}

@media (max-width: 850px) {
    :root {
        --draw-multiplier: 6.29;
    }
}

@media (max-width: 650px) {
    :root {
        --draw-multiplier: 4.735;
    }
}

@media (max-width: 400px) {
    :root {
        --draw-multiplier: 2.1;
    }
}

@media (max-width: 375px) {
    :root {
        --draw-multiplier: 1.61;
    }
}

.vector-container {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.vector-container svg {
    display: block;
    width: 100%;
    height: 100%;
}

.ball {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    background-color: #ffe9ae;
    animation: bounce 0.5s linear infinite alternate;
}

.pepe-tip {
    position: absolute;
    left: 49.3%;
    transform: translateX(-50%);
    width: 45px;
    height: 45px;
}

@keyframes bounce {
    from {
        transform: translateX(-50%) scale(0.5);
    }

    to {
        transform: translateX(-50%) scale(1);
    }
}

@media (max-width: 48em) {
    .vector-container {
        width: 100%;
    }

    .ball {
        left: 50%;
        transform: translateX(-50%);
    }

    .pepe-tip {
        left: 50%;
        width: 25px;
        height: 25px;
        transform: translateX(-50%);
    }
}