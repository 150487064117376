@font-face {
    font-family: 'KGHappy';
    src: url('../../fonts/kg_happy.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'KidsMagazine';
    src: url('../../fonts/kids_magazine.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}